import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_date_picker = _resolveComponent("a-date-picker")!

  return (_openBlock(), _createBlock(_component_a_date_picker, {
    value: _ctx.computedDataSelecionada,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedDataSelecionada) = $event)),
    class: _normalizeClass('ss-tamanho-completo ' + _ctx.props.classCss),
    "show-time": (_ctx.props.horaMinuto || _ctx.props.horaMinutoSegundo),
    format: _ctx.obterFormatoData(),
    placeholder: _ctx.props.placeholder,
    disabled: _ctx.props.disabled,
    onChange: _ctx.change,
    onBlur: _ctx.blur,
    onKeydown: _ctx.keydown
  }, null, 8, ["value", "class", "show-time", "format", "placeholder", "disabled", "onChange", "onBlur", "onKeydown"]))
}