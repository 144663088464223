
import { computed, defineComponent } from 'vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';

export default defineComponent({
  name: 'SelecionarHora',
  props: {
    horaSelecionada: {
      type: String,
      default: '00:00:00',
    },
    minutoSegundo: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:horaSelecionada', 'update:horaMinutoSegundo', 'change'],
  setup(props, { emit }) {
    function obterFormatoHora() {
      if (props.minutoSegundo) {
        return UtilitarioData.formatoHoraMinutoSegundo;
      }
      return UtilitarioData.aplicaFormatoDataHoraMinuto;
    }

    function obterHora() {
      if (UtilitarioGeral.valorValido(props.horaSelecionada)) {
        if (props.minutoSegundo) {
          return UtilitarioData.converterHoraMinutoSegundoParaDayJs(props.horaSelecionada);
        }
        return UtilitarioData.converterHoraMinutoParaDayJs(props.horaSelecionada);
      }

      return undefined;
    }

    const computedHoraSelecionada = computed({
      get: () => obterHora(),
      set: (valor: any) => {
        if (valor === undefined || valor === null) {
          emit('update:horaSelecionada', '');
        } else if (props.minutoSegundo) {
          emit('update:horaSelecionada', UtilitarioData.converterHoraMinutoSegundoJson(valor));
        } else {
          emit('update:horaSelecionada', UtilitarioData.converterHoraMinutoJson(valor));
        }
      },
    });

    function change() {
      emit('change', props.horaSelecionada);
    }

    return {
      props,
      obterFormatoHora,
      computedHoraSelecionada,
      change,
    };
  },
});
