import ApiERP from '@/core/conectores/ApiERP';
import {
  ICampoPersonalizado,
  IColunaCamposPersonalizados,
  IGrupoCamposPersonalizado,
  IItemCampoPersonalizado,
  IValorCampoPersonalizado,
  ILinhaCamposPersonalizados,
  IPersonalizacaoTela,
} from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IDTOPersonalizacaoTela } from '@/models/DTO/MeuSistema/IDTOPersonalizacaoTela';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IItemConsultaRapidaString } from '@/core/models/Consulta/IItemConsultaRapidaString';
/**
 * Serviço de Personalização de Tela
 * Fornece todas regras de negócios e lógicas relacionado a personalizações de telas.
 */

class ServicoPersonalizacaoTela {
  endPoint = 'personalizacoes-telas';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public prepararObjetoEnvioAPI(personalizacao: IPersonalizacaoTela):IPersonalizacaoTela {
    const objeto = UtilitarioGeral.instanciaObjetoLocal(personalizacao);
    return objeto;
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaPropriedades;
  }

  public async obterTodos(): Promise<IDTOPersonalizacaoTela[]> {
    const empresas = storeSistema.getters.empresasUsuarioAutenticado();
    let parametroEmpresas = '';
    if (empresas !== undefined && empresas.length > 0) {
      empresas.forEach((codigoEmpresa) => {
        parametroEmpresas += (parametroEmpresas !== '' ? '&' : '?');
        parametroEmpresas += `Empresas=${codigoEmpresa}`;
      });
    }
    const result: any = await this.apiERP.get(`${this.endPoint}/obter-personalizacoes-telas/${parametroEmpresas}`);
    return result.data;
  }

  public async incluir(personalizacao: IPersonalizacaoTela): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}`, personalizacao);
    return result.data;
  }

  public async alterar(personalizacao: IPersonalizacaoTela): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${personalizacao.codigo}`, this.prepararObjetoEnvioAPI(personalizacao));
    return result.data;
  }

  public async atualizarValoresCampos(registro: number, empresa: number, grupos: IGrupoCamposPersonalizado[]): Promise<IRetornoRequisicao> {
    const objeto = UtilitarioGeral.instanciaObjetoLocal(grupos);
    const result: any = await this.apiERP.put(`${this.endPoint}/atualizar-valores-campos/${registro}/${empresa}`, this.prepararObjetoEnvioAPI(objeto));
    return result.data;
  }

  public async obter(codigo: number, empresa: number): Promise<IPersonalizacaoTela> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/${empresa}`);
    return result.data;
  }

  public async obterPeloRecurso(recurso: string, empresa: number): Promise<IPersonalizacaoTela> {
    const result: any = await this.apiERP.get(`${this.endPoint}/obter-pelo-recurso/${recurso}/${empresa}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterGruposCampos(codigo: number, empresa: number): Promise<IGrupoCamposPersonalizado[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/obter-grupos-campos/${codigo}/${empresa}`);
    return result.data;
  }

  public async obterLinhasCampos(codigo: number, empresa: number): Promise<ILinhaCamposPersonalizados[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/obter-linhas-campos/${codigo}/${empresa}`);
    return result.data;
  }

  public async obterColunasCampos(codigo: number, empresa: number): Promise<IColunaCamposPersonalizados[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/obter-colunas-campos/${codigo}/${empresa}`);
    return result.data;
  }

  public async obterCamposPersonalizados(codigo: number, empresa: number): Promise<ICampoPersonalizado[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/obter-campos-personalizados/${codigo}/${empresa}`);
    return result.data;
  }

  public async obterItensCampo(codigo: number, empresa: number): Promise<IItemCampoPersonalizado[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/obter-itens-campo-personalizado/${codigo}/${empresa}`);
    return result.data;
  }

  public async obterValoresCampos(registro: number, grupo: number, empresa: number): Promise<IValorCampoPersonalizado[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/obter-valores-campos-personalizados/${registro}/${grupo}/${empresa}`);
    return result.data;
  }

  public async consultaRapidaItensCampos(codigoCampo:number, parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapidaString[]> {
    const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    const result: any = await this.apiERP.get(`${this.endPoint}/campo/${codigoCampo}/itens/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }
}

export default ServicoPersonalizacaoTela;
