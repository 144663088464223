import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ant-input-wrapper ant-input-group ss-alinhar-texto-direita"
}
const _hoisted_2 = ["value", "title", "data-coluna", "disabled"]
const _hoisted_3 = {
  key: 1,
  class: "ant-input-wrapper ant-input-group ss-alinhar-texto-direita"
}
const _hoisted_4 = ["value", "title", "data-coluna", "disabled"]
const _hoisted_5 = {
  key: 2,
  class: "ss-alinhar-texto-direita"
}
const _hoisted_6 = ["title", "data-coluna", "disabled"]
const _hoisted_7 = {
  key: 3,
  class: "ant-input-wrapper ant-input-group ss-alinhar-texto-direita"
}
const _hoisted_8 = ["value", "title", "data-coluna", "disabled"]
const _hoisted_9 = { key: 4 }
const _hoisted_10 = ["value", "title", "data-coluna", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_ctx.props.monetario)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass('ant-input-group-addon ' + _ctx.props.classGroupCss)
        }, "R$", 2),
        _createElementVNode("input", {
          ref: "refCampoNumerico",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.change())),
          onKeypress: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validarInformacaoDigitada($event))),
          onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.valorDigitado($event))),
          onKeydown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.valorDigitado($event))),
          type: "text",
          onPaste: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validarInformacaoColada($event))),
          class: _normalizeClass('ant-input ' + _ctx.props.classCss),
          value: _ctx.apresentaValorMascarado(),
          onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.digitandoValor($event))),
          title: _ctx.props.title,
          "data-coluna": _ctx.props.dataAttributeColuna,
          disabled: _ctx.props.disabled,
          onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.inputFocus($event))),
          onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.blur()))
        }, null, 42, _hoisted_2)
      ]))
    : (_ctx.props.percentual)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createElementVNode("input", {
            ref: "refCampoNumerico",
            onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.change())),
            onKeypress: _cache[9] || (_cache[9] = ($event: any) => (_ctx.validarInformacaoDigitada($event))),
            onKeyup: _cache[10] || (_cache[10] = ($event: any) => (_ctx.valorDigitado($event))),
            onKeydown: _cache[11] || (_cache[11] = ($event: any) => (_ctx.valorDigitado($event))),
            type: "text",
            onPaste: _cache[12] || (_cache[12] = ($event: any) => (_ctx.validarInformacaoColada($event))),
            class: _normalizeClass('ant-input ' + _ctx.props.classCss),
            value: _ctx.apresentaValorMascarado(),
            onInput: _cache[13] || (_cache[13] = ($event: any) => (_ctx.digitandoValor($event))),
            title: _ctx.props.title,
            "data-coluna": _ctx.props.dataAttributeColuna,
            disabled: _ctx.props.disabled,
            onFocus: _cache[14] || (_cache[14] = ($event: any) => (_ctx.inputFocus($event))),
            onBlur: _cache[15] || (_cache[15] = ($event: any) => (_ctx.blur()))
          }, null, 42, _hoisted_4),
          _createElementVNode("span", {
            class: _normalizeClass('ant-input-group-addon ' + _ctx.props.classGroupCss)
          }, "%", 2)
        ]))
      : (_ctx.props.mascara !== '' && _ctx.props.mascara !== undefined)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              ref: "refCampoNumerico",
              onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.change())),
              onKeypress: _cache[17] || (_cache[17] = ($event: any) => (_ctx.validarInformacaoDigitada($event))),
              onKeyup: _cache[18] || (_cache[18] = ($event: any) => (_ctx.valorDigitado($event))),
              onKeydown: _cache[19] || (_cache[19] = ($event: any) => (_ctx.valorDigitado($event))),
              type: "text",
              onPaste: _cache[20] || (_cache[20] = ($event: any) => (_ctx.validarInformacaoColada($event))),
              class: _normalizeClass('ant-input ' + _ctx.props.classCss),
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.computedValor) = $event)),
              title: _ctx.props.title,
              "data-coluna": _ctx.props.dataAttributeColuna,
              disabled: _ctx.props.disabled,
              onFocus: _cache[22] || (_cache[22] = ($event: any) => (_ctx.inputFocus($event))),
              onBlur: _cache[23] || (_cache[23] = ($event: any) => (_ctx.blur()))
            }, null, 42, _hoisted_6), [
              [_vModelText, _ctx.computedValor],
              [_directive_maska, _ctx.props.mascara]
            ])
          ]))
        : (_ctx.props.info !== '' && _ctx.props.info !== undefined)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
              _createElementVNode("span", {
                class: _normalizeClass('ant-input-group-addon ' + _ctx.props.classGroupCss)
              }, _toDisplayString(_ctx.props.info), 3),
              _createElementVNode("input", {
                ref: "refCampoNumerico",
                onChange: _cache[24] || (_cache[24] = ($event: any) => (_ctx.change())),
                onKeypress: _cache[25] || (_cache[25] = ($event: any) => (_ctx.validarInformacaoDigitada($event))),
                onKeyup: _cache[26] || (_cache[26] = ($event: any) => (_ctx.valorDigitado($event))),
                onKeydown: _cache[27] || (_cache[27] = ($event: any) => (_ctx.valorDigitado($event))),
                type: "text",
                onPaste: _cache[28] || (_cache[28] = ($event: any) => (_ctx.validarInformacaoColada($event))),
                class: _normalizeClass('ant-input ' + _ctx.props.classCss),
                value: _ctx.apresentaValorMascarado(),
                onInput: _cache[29] || (_cache[29] = ($event: any) => (_ctx.digitandoValor($event))),
                title: _ctx.props.title,
                "data-coluna": _ctx.props.dataAttributeColuna,
                disabled: _ctx.props.disabled,
                onFocus: _cache[30] || (_cache[30] = ($event: any) => (_ctx.inputFocus($event))),
                onBlur: _cache[31] || (_cache[31] = ($event: any) => (_ctx.blur()))
              }, null, 42, _hoisted_8)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_9, [
              _createElementVNode("input", {
                ref: "refCampoNumerico",
                onChange: _cache[32] || (_cache[32] = ($event: any) => (_ctx.change())),
                onKeypress: _cache[33] || (_cache[33] = ($event: any) => (_ctx.validarInformacaoDigitada($event))),
                onKeyup: _cache[34] || (_cache[34] = ($event: any) => (_ctx.valorDigitado($event))),
                onKeydown: _cache[35] || (_cache[35] = ($event: any) => (_ctx.valorDigitado($event))),
                type: "text",
                onPaste: _cache[36] || (_cache[36] = ($event: any) => (_ctx.validarInformacaoColada($event))),
                class: _normalizeClass('ant-input ss-alinhar-texto-direita ' + _ctx.props.classCss),
                value: _ctx.apresentaValorMascarado(),
                onInput: _cache[37] || (_cache[37] = ($event: any) => (_ctx.digitandoValor($event))),
                title: _ctx.props.title,
                "data-coluna": _ctx.props.dataAttributeColuna,
                disabled: _ctx.props.disabled,
                onFocus: _cache[38] || (_cache[38] = ($event: any) => (_ctx.inputFocus($event))),
                onBlur: _cache[39] || (_cache[39] = ($event: any) => (_ctx.blur()))
              }, null, 42, _hoisted_10)
            ]))
}