import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_time_picker = _resolveComponent("a-time-picker")!

  return (_openBlock(), _createBlock(_component_a_time_picker, {
    value: _ctx.computedHoraSelecionada,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedHoraSelecionada) = $event)),
    class: "ss-tamanho-completo",
    format: _ctx.props.minutoSegundo? 'HH:mm:ss':'HH:mm',
    disabled: _ctx.props.disabled,
    onChange: _ctx.change
  }, null, 8, ["value", "format", "disabled", "onChange"]))
}