
import { defineComponent, computed } from 'vue';
import { IItemCampoPersonalizado } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';

export default defineComponent({
  name: 'SelecionarValorCampoPersonalizado',
  components: {
  },
  props: {
    valores: {
      type: Object as () => IItemCampoPersonalizado[],
      required: true,
    },
    valor: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: string) => {
        emit('update:valor', valor);
      },
    });

    function change() {
      emit('change', props.valor);
    }

    return {
      props,
      computedValor,
      change,
    };
  },
});
