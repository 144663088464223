export enum ETipoCampoPersonalizado {
    Texto = 1,
    AreaTexto = 2,
    Inteiro = 3,
    Fracionado = 4,
    Email = 5,
    Senha = 6,
    ComboBox = 7,
    ListaSelecao = 8,
    Data = 9,
    Hora = 10,
    Check = 11,
    Switch = 12,
}
