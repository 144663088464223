
import {
  computed, defineComponent, reactive,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'SelecionarEmpresa',
  props: {
    varias: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    apenasAutorizadasUsuario: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    empresasEspecificas: {
      type: Array as () => IOption[],
      default: () => [],
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'change'],
  setup(props, { emit }) {
    const state = reactive({
      listaEmpresas: [] as IOption[],
    });
    state.listaEmpresas = [];
    if (props.apenasAutorizadasUsuario) {
      const empresasUsuario = storeSistema.getters.dadosEmpresasAutorizadasUsuario();
      state.listaEmpresas = empresasUsuario.map((empresa) => ({
        label: UtilitarioGeral.montaExibicaoEmpresa(empresa.codigoExibicao, empresa.nomeExibicao),
        value: empresa.codigoEmpresa,
      }));
    } else {
      state.listaEmpresas = storeSistema.state.empresasDisponiveis.map((item) => ({
        label: UtilitarioGeral.montaExibicaoEmpresa(item.codigoExibicao, item.nomeExibicao),
        value: item.codigoEmpresa,
      }));
    }

    function obterEmpresa():any {
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedCodigoSelecionado = computed({
      get: () => obterEmpresa(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    const computedCodigosSelecionados = computed({
      get: () => props.codigosSelecionados,
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varias ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
    };
  },
});
